import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

import * as privacyStyle from "./privcay.module.css"

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "privacy-banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout>
      <SEO title="隐私条例" />
      <div className={`mainContent`}>
        <Header
          activePage="privacy"
          color={{ bg: "transparent", text: "#000" }}
        />

        <div className="banner" data-bg-color="transparent" data-color="#000">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>

        <div
          className="privacy-content privacy-content-cn js-viewport-animation"
          data-bg-color="transparent"
          data-color="#000"
        >
          <div className="wp1180">
            <h1 className="title">隐私政策</h1>
            <p class="js-text-animation">
              生效日期：2019年12月9日 <br />
              S＆W（“我们”，“我们”或“我们的”）运营http://www.sunnwell.com/网站（以下称为“服务”）。
            </p>
            <p class="js-text-animation">
              本页告知您在使用我们的服务时有关收集，使用和披露个人数据的政策以及与该数据相关的选择。我们使用您的数据来提供和改善服务。使用服务，即表示您同意按照本政策收集和使用信息。除非本隐私政策中另有定义，否则本隐私政策中使用的术语与我们的条款和条件具有相同的含义，可从http://www.sunnwell.com/访问。
            </p>
            <div className="part">
              <h2>信息收集与使用</h2>
              <p>
                我们出于各种目的收集几种不同类型的信息，以向您提供和改善我们的服务。
              </p>
              <br />
              <br />
              <h3>收集的数据类型</h3>
              <h4>个人资料</h4>
              <p>
                在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您的个人身份信息（“个人数据”）。
                个人身份信息可能包括但不限于：
              </p>
              <ul>
                <li className="pl1">
                  <h5>• Cookie和使用情况数据</h5>
                  <ul className="pl2">
                    <li>
                      <h6>使用数据</h6>
                      <p>
                        我们也可能收集有关如何访问和使用服务的信息（“使用数据”）。此使用情况数据可能包括诸如计算机的Internet协议地址（例如IP地址），浏览器类型，浏览器版本，您访问的“服务”页面，访问的时间和日期，在这些页面上花费的时间，唯一性等信息。设备标识符和其他诊断数据。
                      </p>
                    </li>
                    <li>
                      <h6>跟踪和Cookie数据</h6>
                      <p>
                        我们使用Cookie和类似的跟踪技术来跟踪我们服务上的活动并保存某些信息。{" "}
                        <br />
                        Cookies是具有少量数据的文件，其中可能包含匿名唯一标识符。
                        Cookies从网站发送到您的浏览器，并存储在您的设备上。信标，标签和脚本也用于跟踪技术，以收集和跟踪信息以及改善和分析我们的服务。{" "}
                        <br />
                        您可以指示浏览器拒绝所有cookie或指示何时发送cookie。但是，如果您不接受Cookie，则可能无法使用我们服务的某些部分。您可以在《浏览器Cookies指南》中了解更多有关如何管理cookie的信息。
                      </p>
                    </li>
                    <li>
                      <h6>我们使用的Cookie的示例：</h6>
                      <p className="pl3">
                        • 会话Cookie: 我们使用会话Cookie来运行我们的服务。{" "}
                        <br />• 首选项Cookie:
                        我们使用首选项Cookie来记住您的首选项和各种设置。 <br />•
                        安全Cookies: 我们出于安全目的使用安全cookie。
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="part">
              <h2>资料使用</h2>
              <h5>S＆W将收集的数据用于各种目的：</h5>
              <div className="pl2">
                <p>
                  • 提供和维护服务 <br />
                  • 通知您我们服务的变更 <br />
                  • 使您可以选择参与我们服务的互动功能 <br />
                  • 提供客户服务和支持 <br />
                  • 提供分析或有价值的信息，以便我们改善服务
                  <br />
                  • 监视服务的使用情况 <br />• 检测，预防和解决技术问题
                </p>
              </div>
            </div>

            <div className="part">
              <h2>资料传输</h2>
              <div className="pl2">
                <p>
                  您的信息（包括个人数据）可能会转移到并保存在您所在州，省，国家或其他政府辖区之外的计算机上，在这些计算机上，数据保护法可能与您所在辖区的法律不同。{" "}
                  <br />
                  如果您位于中国境外并选择向我们提供信息，请注意，我们会将包括个人数据在内的数据传输到中国并在中国进行处理。{" "}
                  <br />
                  您同意本隐私政策并随后提交此类信息，即表示您同意该转移。
                  <br />
                  S＆W将采取合理必要的所有步骤，以确保按照本隐私政策安全地处理您的数据，除非有足够的控制措施，包括您的安全性，否则不会将您的个人数据转移到组织或国家/地区。
                  数据和其他个人信息。
                </p>
              </div>
            </div>

            <div className="part">
              <h2>资料披露</h2>
              <div className="pl2">
                <h5>法律要求</h5>
                <p>S＆W可能真诚地认为您必须采取以下行动来披露您的个人数据：</p>
                <p>
                  • 遵守法律义务
                  <br />
                  • 保护和捍卫S＆W的权利或财产
                  <br />
                  • 防止或调查与服务有关的可能的不当行为
                  <br />• 为了保护服务用户或公众的人身安全 • 防止法律责任
                </p>
              </div>
            </div>

            <div className="part">
              <h2>数据安全</h2>
              <div className="pl2">
                <p>
                  数据的安全性对我们很重要，但是请记住，互联网上的传输方法或电子存储方法都不是100％安全的。
                  尽管我们努力使用商业上可接受的方式来保护您的个人数据，但我们不能保证其绝对安全。
                </p>
              </div>
            </div>

            <div className="part">
              <h2>服务供应商</h2>
              <div className="pl2">
                <p>
                  我们可能会雇用第三方公司和个人来促进我们的服务（“服务提供商”），代表我们提供服务，执行与服务相关的服务或协助我们分析服务的使用方式。这些第三方仅能代表我们执行这些任务而访问您的个人数据，并且有义务不将其披露或用于任何其他目的。
                </p>
                <p>
                  链接到其他网站 <br />
                  我们的服务可能包含指向其他非我们运营网站的链接。
                  如果单击第三方链接，您将被定向到该第三方的网站。
                  我们强烈建议您查看您访问的每个网站的隐私政策。 <br />
                  我们无法控制任何第三方网站或服务的内容，隐私权政策或行为，也不承担任何责任。
                </p>
              </div>
            </div>

            <div className="part">
              <h2>儿童隐私</h2>
              <div className="pl2">
                <p>
                  我们的服务不针对18岁以下的任何人（“儿童”）。 <br />
                  我们不会有意收集任何未满18岁的个人身份信息。如果您是父母或监护人，并且您知道自己的孩子向我们提供了个人数据，请与我们联系。
                  如果我们知道我们是在未获得父母同意的情况下从孩子那里收集个人数据的，则我们将采取措施从服务器中删除该信息。
                </p>
              </div>
            </div>

            <div className="part">
              <h2>本隐私政策的变更</h2>
              <div className="pl2">
                <p>
                  我们可能会不时更新我们的隐私政策。
                  我们会通过在此页面上发布新的隐私政策来通知您任何更改。 <br />
                  在更改生效之前，我们将通过电子邮件和/或服务的重要通知告知您，并在本隐私政策的顶部更新“生效日期”。
                  <br />
                  建议您定期阅读本隐私政策以进行任何更改。
                  在本页面上发布对本隐私政策的更改后，它们才生效。
                </p>
              </div>
            </div>

            <div className="part">
              <h2>联系我们</h2>
              <div className="pl2">
                <p>
                  如果您对此隐私政策有任何疑问，请联系我们：
                  <br />• 通过电子邮件：
                  <a href="mailto:info@sunnwell.com">info@sunnwell.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PrivacyPage
